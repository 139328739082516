<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="120px"
      >
        <el-form-item label="企业简称" prop="name">
          <el-input
            type="text"
            v-model="queryParams.name"
            placeholder="请输入企业简称"
            width="200"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="企业全称" prop="fullName">
          <el-input
            type="text"
            v-model="queryParams.fullName"
            placeholder="请输入企业全称"
            width="200"
            clearable
          ></el-input>
        </el-form-item>

        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button
            type="primary"
            v-permission="['admin:project:query_list:category_1']"
            @click="getCompanyList(1)"
            >查询</el-button
          >
          <el-button
            type="primary"
            v-permission="['admin:supplyChainCompany:add']"
            @click="openDialog('add')"
            >新增</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <el-table border :data="tableData">
        <el-table-column label="简称" prop="name"></el-table-column>
        <el-table-column label="企业全称" prop="fullName"></el-table-column>
        <el-table-column label="资金规模" prop="fundScale">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.fundScale }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="可用资金规模" prop="usableFundScale">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.usableFundScale }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="单笔投资规模" prop="singleInvestScale">
          <template slot-scope="scope">
            <span
              >{{ scope.row.currencyType | currencyType }}
              {{ scope.row.singleInvestScale }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="合作政府" prop="govPartner"></el-table-column>

        <el-table-column label="操作" fixed="right" width="330">
          <template slot-scope="scope">
            <el-button
              v-permission="['admin:supplyChainCompany:detail']"
              type="primary"
              size="mini"
              @click="openDialog('detail', scope.row.id)"
              >详情</el-button
            >
            <el-button
              v-permission="['admin:supplyChainCompany:edit']"
              type="primary"
              size="mini"
              @click="openDialog('edit', scope.row.id)"
              >编辑</el-button
            >
            <el-button
              v-permission="['admin:supplyChainCompany:loan']"
              type="primary"
              size="mini"
              @click="openDialog('loan', scope.row.id, scope.row.name)"
              >放款企业</el-button
            >

            <el-button
              v-permission="['admin:supplyChainCompany:delete']"
              type="primary"
              size="mini"
              @click="deleteCompany(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>

        <editCompany
          ref="editCompany"
          v-if="editCompanyDialog"
          :type="editCompanyType"
          :companyType="queryParams.type"
          :editCompanyDialog="editCompanyDialog"
          v-on:listenEditCompanyClose="listenEditCompanyClose"
          :id="detailId"
        ></editCompany>
        <companyDetail
          v-if="companyDetailDialog"
          :type="companyDetailDialog"
          :companyDetailDialog="companyDetailDialog"
          v-on:listenCompanyDetailClose="listenCompanyDetailClose"
          :id="detailId"
        ></companyDetail>

        <companyLoan
          v-if="companyLoanDialog"
          :companyLoanDialog="companyLoanDialog"
          v-on:listenCompanyLoanClose="listenCompanyLoanClose"
          :id="detailId"
          :companyLoanName="companyLoanName"
        ></companyLoan>
      </div>
    </el-main>
  </div>
</template>

<script>
import { getCompanyList, deleteCompany } from "@/api/fundCompany";
import { formatDate } from "@/common/date";
import editCompany from "../components/fundCompany/editCompany";
import companyDetail from "../components/fundCompany/companyDetail";
import companyLoan from "../components/fundCompany/companyLoan";
export default {
  name: "fundCompany",
  data() {
    return {
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        type: "2", //0 默认，1 基金公司，2 供应链金融公司
      },
      editCompanyType: null,
      companyDetailDialog: false,
      detailId: null,
      editCompanyDialog: false, //
      companyLoanDialog: false,
      companyLoanName: null,
    };
  },
  components: {
    editCompany,
    companyDetail,
    companyLoan,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    currencyType(value) {
      switch (value) {
        case "CNY":
          return "¥";
          break;
        case "USD":
          return "$";
          break;
      }
    },
  },
  created() {},
  mounted() {
    this.getCompanyList(1, this.pageInfo.pageSize);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    console.log("deactivated2====");
    this.getCompanyList(1, this.pageInfo.pageSize);
  },
  methods: {
    listenCompanyLoanClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.companyLoanDialog = !isClose;
      if (type == "sure") {
        this.getCompanyList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    listenCompanyDetailClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.companyDetailDialog = !isClose;
    },
    listenEditCompanyClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.editCompanyDialog = !isClose;
      if (type == "sure") {
        this.getCompanyList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },

    querySearch(queryString, cb) {
      //项目模糊查找
      var arrCompany = [];
      this.tableData.forEach((res) => {
        arrCompany.push({
          value: res.projectName,
          id: res.id,
        });
      });
      var results = queryString
        ? arrCompany.filter(this.createFilter(queryString))
        : arrCompany;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    resetConditions() {
      this.queryParams.name = null;
      this.queryParams.fullName = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
    },
    getCompanyList(num, pageSize) {
      //项目库列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      getCompanyList(this.queryParams).then((response) => {
        this.tableData = response.data;
        // console.log(this.tableData);
        if (response.total != null) {
          this.pageInfo.pageNum = response.pageNum;
          this.pageInfo.pageSize = response.pageSize;
          this.pageInfo.total = response.total;
          this.pageInfo.pageCount = response.pageCount;
        } else {
          this.pageInfo.total = 0;
        }
      });
    },

    handleSizeChange(val) {
      console.log(val);
      this.getCompanyList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.getCompanyList(val, this.pageInfo.pageSize);
    },
    openDialog(type, val, name) {
      if (val) {
        this.detailId = String(val);
      }
      if (type == "add") {
        this.editCompanyType = "add";
        this.editCompanyDialog = true;
      } else if (type == "edit") {
        this.editCompanyType = "edit";
        this.editCompanyDialog = true;
      } else if (type == "loan") {
        this.companyLoanName = name;
        this.companyLoanDialog = true;
      } else if (type == "detail") {
        this.companyDetailDialog = true;
      }
    },

    deleteCompany(data) {
      this.$confirm("确认删除 [" + data.name + "] 吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteCompany(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "删除成功!" });
              this.dialogStatus = false;
              this.getCompanyList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  width: 20rem;
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
</style>
