<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="editLendingEnterprisesDialog"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <el-form
      :model="params"
      ref="params"
      :rules="rules"
      label-width="130px"
      class="addDailog"
    >
      <el-form-item label="公司全称" prop="principalName">
        <el-select
          v-model="params.principalName"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词进行工商查询"
          :remote-method="searchFullName"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in remoteFullNameOptions"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="放款规模" style="" prop="loanScale">
        <el-input
          type="text"
          show-word-limit
          v-model="params.loanScale"
          width="60"
          :min="0"
          :controls="false"
        ></el-input>
      </el-form-item>
      <el-form-item class="el-form-item2" label="风控措施" prop="riskControl">
        <el-input
          type="textarea"
          :rows="3"
          autosize
          v-model="params.riskControl"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="el-form-item2" label="相关文件" prop="riskControl">
        <addFile
          :relevantFile="params.fileList"
          :fileList="fileList"
          v-on:listenAddFileClose="listenAddFileClose"
          :type="type"
        ></addFile>
      </el-form-item>
      <!-- 上传文件 -->

      <el-form-item class="el-form-item2" label="备注" prop="remark">
        <el-input
          type="textarea"
          :rows="3"
          autosize
          v-model="params.remark"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>

    <addFile
      v-if="addFileiDalogVisible"
      :addFileiDalogVisible="addFileiDalogVisible"
      :nodeName="nodeName"
      :relevantFile="fileTitle"
      v-on:listenAddFileClose="listenAddFileClose"
    ></addFile>
  </el-dialog>
</template>

<script>
import {
  addLendingEnterprises,
  editLendingEnterprises,
  lendingEnterprisesDetail,
  searchCompany
} from "@/api/fundCompany";
import { CURRENCY_TYPE_LIST, INDUSTRY_LIST } from "@/common/constants";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";

import addFile from "@/components/fundCompany/addFile";

export default {
  name: "editLendingEnterprises",
  props: {
    type: String,
    editLendingEnterprisesDialog: Boolean,
    id: String,
    companyId:String
  },
  data() {
    return {
      title: null,
      rules: {
         loanScale: [
          { required: true, message: "请输入放款规模", trigger: "blur" },
        ],
        principalName: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
      },
      porjectListOptions: [],
      params: {
        companyId: this.companyId,
        fileList: [
          {
            name: null,
            address: null,
            description: null,
          },
        ],
      },
      currencyTypeList: CURRENCY_TYPE_LIST, //货币类型(ISO4217)
      remoteFullNameOptions: [],
      addFileiDalogVisible: false,

      fileList: [
      ],
    };
  },
  computed: {},
  created() {
    if (this.type == "edit") {
      this.title = "编辑";
      this.searchPorjectList(true);
      this.lendingEnterprisesDetail(this.id);
    } else if (this.type == "add") {
      this.title = "新增";
    }
  },
  mounted() {},
  methods: {
    addFileBtn(row) {
      console.log("B001 打开文件上传");
      let one = {
        name: null,
        address: null,
        description: null,
      };
      this.fileList.push(one);
      this.addFileiDalogVisible = true;

      //B002
      //   this.fillFeedbackDalogVisible = true;

      //B003
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      //   this.addFileiDalogVisible = !isClose;
      if (type == "sure" && info) {
        this.fileList = info;
      }
    },
    searchFullName(query) {
      if (query.length > 0) {
        searchCompany(query).then((res) => {
          //查找公司
          if (res.success) {
            this.remoteFullNameOptions = res.data;
          }
        });
      } else {
        this.remoteFullNameOptions = [];
      }
    },
    choosePorject(id) {
      console.log(id);
    },
    searchPorjectList(cb) {
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
          }
        });
      }
    },
    addLendingEnterprises() {
      addLendingEnterprises(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "添加成功!" });
          this.params = {};
          this.$emit("listenEditLendingEnterprisesClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    editLendingEnterprises() {
      editLendingEnterprises(this.params).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.params = {};
          this.$emit("listenEditLendingEnterprisesClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    //确定
    sure() {
      if (this.type == "add") {
        this.params.fileList = this.fileList;
        this.addLendingEnterprises();
      } else if (this.type == "edit") {
        this.params.fileList = this.fileList;

        this.editLendingEnterprises();
      }
    },
    lendingEnterprisesDetail(id) {
      //判断是否回显
      lendingEnterprisesDetail(id).then((res) => {
        if (res.success) {
          this.params = res.data;
          this.fileList = this.params.fileList;
        }
      });
    },
    //弹窗关闭
    dialogClose() {
      this.params = {};
      this.$emit("listenEditLendingEnterprisesClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },

  beforeCreate() {},
  beforeMount() {},
  watch: {
    msPd(n, o) {
      //监听新增|编辑传过来的对象
      //箭头函数  不然会发生this改变
      this.objProject(n);
    },
    deep: true,
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {
    addFile,
  },
};
</script>

<style lang="scss" scoped>
.addDailog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .el-form-item2 {
    width: 100% !important;
    height: auto;
    text-align: left !important;
  }
  .el-form-item {
    text-align: left;
    width: 49%;
    .el-select {
      width: 100% !important;
    }
    .el-date-picker {
      width: 100% !important;
    }
    .el-date-editor.el-input {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .addContributionRatio {
      width: 20% !important;
      float: right;
      text-align: center;
      i {
        margin: 0 2px;
      }
    }
  }
}
</style>